import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { HeroTitleAnimated, TickerContainer } from './styles'
import GlobalContext from 'context/GlobalStateProvider'
import Marquee from 'react-fast-marquee'

const TextTicker = ({ query, background_fallback }) => {
  const globalContext = useContext(GlobalContext)

  return (
    <TickerContainer
      openMenu={globalContext?.openMenu}
      className={`ticker-container ${
        background_fallback ? 'has-background-color' : ''
      }`}
      backgroundColor={background_fallback}
    >
      <Marquee speed={90} gradient={false}>
        <HeroTitleAnimated
          as="h1"
          type="h1"
          whiteTitle
          className={`ticker_content`}
        >
          {query}.
        </HeroTitleAnimated>
      </Marquee>
    </TickerContainer>
  )
}

TextTicker.propTypes = {
  query: PropTypes.string.isRequired,
  speed: PropTypes.number.isRequired,
}

export default TextTicker
