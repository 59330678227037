import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const HeroMainStyled = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  min-height: ${props => (props.hasFullHeight ? '100vh' : '481px')};
  overflow: hidden;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    min-height: ${props => (props.hasFullHeight ? '100vh' : '688px')};
  }

  > div {
    display: flex;
    align-items: center;
  }
`

export const HeroImageContainer = styled(BackgroundImage)`
  min-height: ${props => (props.hasFullHeight ? '100vh' : '481px')};
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    min-height: ${props => (props.hasFullHeight ? '100vh' : '688px')};
  }
`

export const HeroVideoContainer = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  min-height: ${props => (props.hasFullHeight ? '100vh' : '100vh')};
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    min-height: ${props => (props.hasFullHeight ? '100vh' : 'initial')};
  }

  .HeroContainer__VideoBackground {
    display: flex;
    align-items: center;
    min-width: 100vw !important;

    @media (min-width: 1025px) {
      min-height: 100vh !important;
    }

    video {
      @media (min-width: 1025px) {
        transform: translate(-50%, -50%) !important;
        left: 50%;
        position: absolute;
        top: 50%;
        min-width: 100vw;
        width: auto !important;
        height: auto !important;
        min-height: 100vh !important;
      }

      @media (min-width: 1200px) {
        width: 100% !important;
      }
    }
  }
`

export const HeroImage = styled(Image)``

export const HeroContentStyled = styled.div`
  height: auto;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  display: flex;
  padding: ${props => (!props.isAnimated ? '20px' : '0 !important')};
  overflow: visible;

  .Title,
  .Text {
    display: ${props => (props.disableTitle ? 'none' : '')};
    color: ${props =>
      props.lightColorTheme ? props.theme.colors.white : null};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: auto;
    padding: 150px 50px;
    align-items: center;
    margin: ${props => (props.isAnimated ? 'unset' : '0 auto')};
    max-width: ${props => (props.isAnimated ? 'unset' : '2000px')};
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 150px 186px;
  }

  .ticker-container {
    width: 100%;
    height: 100%;
  }

  .ticker {
    white-space: nowrap;
    margin: 0 auto;

    .ticker_content {
      white-space: nowrap;
      display: block;
    }

    .AnimatedText__StartingPoint {
      padding-left: 50px;
    }

    .ticker__element {
      padding-right: 20px;
      min-width: 100%;

      > * {
        min-width: 100%;
      }
    }
  }
`

export const HeroTitleNotAnimatedSmall = styled(Title)`
  color: ${props => props.theme.colors.primarygreen};
  margin-right: 50px;
  white-space: pre-wrap;
  min-width: 280px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 0;
    padding-bottom: 30px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 100px;
    min-width: 300px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-right: 150px;
  }
`

export const HeroTitleNotAnimatedContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    width: 100%;
    align-items: baseline;
    margin-top: ${props => (props.hasBackground ? '200px' : '0px')};
  }
`

export const HeroTitleNotAnimatedBig = styled(Title)`
  color: ${props => props.theme.colors.primarygreen};
`

export const HeroBody = styled(Text)`
  color: ${props => props.theme.colors.primarygreen};
  font-weight: 500;
  line-height: 1.47;
  max-width: 600px;
`
