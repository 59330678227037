import React, { useContext, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import ScrollComponent from 'components/ScrollComponent'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  HeroMainStyled,
  HeroImageContainer,
  HeroVideoContainer,
  HeroContentStyled,
  HeroTitleNotAnimatedSmall,
  HeroTitleNotAnimatedContainer,
  HeroTitleNotAnimatedBig,
  HeroBody,
} from './styles'
import TextTicker from 'components/TextTicker'
import GlobalContext from 'context/GlobalStateProvider'

const Hero = props => {
  const globalContext = useContext(GlobalContext)
  const videoRef = useRef(null)
  const {
    image,
    scroll_down_label,
    title_style,
    video,
    video_mobile,
    background_fallback,
    full_screen_height,
    title,
    body,
    className,
    disable_title: disableTitle,
  } = props

  const hasBackground =
    image?.localFile?.childImageSharp?.fluid ||
    background_fallback ||
    video?.url

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('1025'))

  useEffect(() => {
    if (hasBackground && globalContext) {
      globalContext?.setLightColorTheme(true)
    } else {
      globalContext?.setLightColorTheme(false)
    }
  }, [globalContext, hasBackground])

  const reactPlayerProps = {
    width: '100%',
    height: '100%',
    className: 'HeroContainer__VideoBackground',
    control: false,
    muted: true,
    loop: true,
    playsinline: true,
    autoplay: true,
    play: true,
    playing: true,
    onReady: () => {
      videoRef?.current?.wrapper?.querySelector('video').play()
    },
  }

  const heroContent = (
    <HeroContentStyled
      className={className ? className : null}
      flexContainer={title_style !== 'animated'}
      isAnimated={title_style === 'animated'}
      backgroundColor={background_fallback}
      lightColorTheme={hasBackground}
      disableTitle={disableTitle}
    >
      {title_style === 'animated' ? (
        <TextTicker
          query={title?.text}
          background_fallback={background_fallback}
        />
      ) : title_style === 'big' ? (
        <HeroTitleNotAnimatedBig type="h1-secondary-large" as="h1">
          {title?.text}
        </HeroTitleNotAnimatedBig>
      ) : title_style === 'small' && body?.text ? (
        <HeroTitleNotAnimatedContainer hasBackground={hasBackground}>
          <HeroTitleNotAnimatedSmall as="h2">
            {title?.text}
          </HeroTitleNotAnimatedSmall>
          <HeroBody>{body?.text}</HeroBody>
        </HeroTitleNotAnimatedContainer>
      ) : (
        <></>
      )}
    </HeroContentStyled>
  )

  return video?.url ? (
    <HeroVideoContainer>
      <ReactPlayer
        ref={videoRef}
        url={isDesktop ? video?.url : video_mobile?.url}
        {...reactPlayerProps}
      />
      {title_style === 'animated' && (
        <ScrollComponent
          scroll_down_label={scroll_down_label}
          scrollDownVideoProps={true}
        />
      )}
    </HeroVideoContainer>
  ) : hasBackground ? (
    <HeroMainStyled
      hasFullHeight={full_screen_height}
      isAnimated={title_style === 'animated'}
    >
      {image?.localFile?.childImageSharp?.fluid ? (
        <HeroImageContainer
          hasFullHeight={full_screen_height}
          fluid={image?.localFile?.childImageSharp?.fluid}
          imgStyle={{ objectFit: 'contain' }}
          alt={image?.alt}
        >
          {heroContent}
          {title_style === 'animated' ? (
            <ScrollComponent
              scroll_down_label={scroll_down_label}
              scrollDownVideoProps={false}
            />
          ) : (
            <></>
          )}
        </HeroImageContainer>
      ) : background_fallback ? (
        <>
          {heroContent}
          {title_style === 'animated' ? (
            <ScrollComponent
              scroll_down_label={scroll_down_label}
              scrollDownVideoProps={false}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </HeroMainStyled>
  ) : (
    heroContent
  )
}

export default Hero
