import React from 'react'
import ScrollLineElement from 'components/Icon'
import { ScrollComponentContainer, ScrollText } from './styles'

const ScrollComponent = ({ scroll_down_label, scrollDownVideoProps}) => {

  return (
    <ScrollComponentContainer scrollDownVideoProps={scrollDownVideoProps}>
      {scroll_down_label?.text ? (
        <>
          <ScrollText whiteText as="p" type="super-small" >
            {scroll_down_label.text}
          </ScrollText>
          <ScrollLineElement type="scroll-line"/>
        </>
      ) : (
        <> </>
      )}
    </ScrollComponentContainer>
  )
}

export default ScrollComponent
