import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} ${props => props.theme.animation.secondary};
`

export const HeroTitleAnimated = styled(Title)`
  font-family: 'EuclidSquare';
  font-weight: 100;
  line-height: 10rem;
  animation: ${reveal1};
`

export const HeroTitleStatic = styled(Title)`
  font-family: 'EuclidSquare';
  font-weight: 100;
  line-height: 10rem;
  opacity: 0;
`

export const TickerContainer = styled.div`
  margin-top: 100px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 100px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -77px;
    padding-top: 77px;
  }

  ::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.backgroundColor};
  }

  .ticker_content {
    white-space: nowrap;
    padding-right: 40px;
  }
`
