import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'

export const ScrollComponentContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  
`

export const ScrollText = styled(Text)`
  position: absolute;
  transform: translate(-50%, -100%);
  bottom: 25px;
  white-space: nowrap;
  text-transform: lowercase;
  font-weight: bold;
`
